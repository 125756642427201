<template>
  <v-main>
    <v-card>
      <v-toolbar color="#FB9300">
        <v-toolbar-title>Pengaturan Promo</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-btn icon>
          <v-icon @click="dialog = true">mdi-plus-circle</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="getDate()">mdi-dots-vertical</v-icon>
        </v-btn>
      </v-toolbar>

      <v-data-table
        fixed-header
        :headers="headers"
        :items="promotion"
        :search="search"
      >
        <template v-slot:[`item.active`]="{ item }">
          <v-chip
            v-if="item.active == true"
            class="ma-2"
            color="green"
            text-color="white"
          >
            Aktif
          </v-chip>
          <v-chip v-else class="ma-2" color="red" text-color="white">
            Tidak Aktif
          </v-chip>
        </template>
        <template v-slot:[`item.timestamp`]="{ item }">
          <h6>
            <span>{{ item.timestamp.toDate().toDateString() }}</span>
          </h6>
        </template>
        <template v-slot:[`item.banner`]="{ item }">
          <h6>
            <v-img
              :aspect-ratio="16 / 9"
              :width="width"
              :src="item.image"
            ></v-img>
          </h6>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn color="yellow darken-2" fab small dark>
            <v-icon @click="openEditDialog(item)">mdi-pencil</v-icon>
          </v-btn>

          <v-btn class="ml-2" color="error" fab small dark>
            <v-icon @click="alertChecker(`menghapus`, item)"
              >mdi-trash-can</v-icon
            >
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">
            {{ editing ? "Edit" : "Tambah" }} Berita
          </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.title"
                    label="Judul"
                    hint="Inputkan headline berita"
                    required
                    :rules="[
                      (v) => !!v || 'Judul Wajib Diisi',
                      (v) =>
                        (v && v.length >= 10) ||
                        'Minimal deskripsi adalah 10 karakter',
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="form.description"
                    outlined
                    name="input-7-4"
                    label="Outlined textarea"
                    :rules="[
                      (v) => !!v || 'Deskripsi Wajib Diisi',
                      (v) =>
                        (v && v.length >= 10) ||
                        'Minimal deskripsi adalah 10 karakter',
                    ]"
                  ></v-textarea>
                </v-col>
                <v-col v-if="editing == true" cols="12">
                  <v-img :width="width" :src="form.imageURL"></v-img>
                </v-col>
                <v-col v-if="!editing">
                  <v-file-input
                    v-model="form.imageFile"
                    accept="image/*"
                    show-size
                    chips
                    truncate-length="15"
                    prepend-icon="mdi-camera"
                    label="File input"
                    :rules="[(v) => !!v || 'Gambar Wajib Diupload']"
                  ></v-file-input>
                </v-col>
                <v-col v-else>
                  <v-file-input
                    v-model="form.imageFile"
                    accept="image/*"
                    show-size
                    chips
                    truncate-length="15"
                    prepend-icon="mdi-camera"
                    label="File input"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-switch v-model="form.active" label="Aktifkan?"></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="(dialog = false), reset()">
            Close
          </v-btn>
          <v-btn v-if="!editing" color="success" @click="validate(form)">
            Save Data
          </v-btn>

          <v-btn v-else color="success" @click="validate(form)">
            Update Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogProgress" max-width="690">
      <v-card class="text-center">
        <h4>Mohon Tunggu..</h4>

        <v-card-text>
          <v-progress-circular
            :rotate="360"
            :size="200"
            :width="30"
            :value="uploadValue"
            color="yellow darken-3"
          >
            {{ uploadValue }}%
          </v-progress-circular>

          <p>Sedang melakukan proses uplaod</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import {
  collection,
  getDocs,
  getFirestore,
  updateDoc,
  doc,
  addDoc,
  deleteDoc,
  serverTimestamp,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import moment from "moment";

import { getAuth } from "firebase/auth";
const auth = getAuth();
const user = auth.currentUser;

import {
  getStorage,
  ref as sRef,
  // uploadBytesResumable,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import Swal from "sweetalert2";

const db = collection(getFirestore(), "promotion");

const dataSorted = query(
  collection(getFirestore(), "user"),
  where("roles", "==", "admin")
);
// console.log(dataSorted);

const timeNow = moment().format("MMMM Do YYYY, h:mm:ss A ");

export default {
  name: "News",
  data() {
    return {
      load: false,
      dialogProgress: false,
      snackbar: false,
      profileName: "",
      valid: true,
      error_message: "",
      dialog: false,
      color: "",
      img: "",
      editing: false,
      promotion: [],
      search: "",
      headers: [
        { text: "Banner", value: "banner" },
        {
          text: "Judul",
          align: "start",
          value: "title",
        },
        { text: "Aktif/Not", value: "active" },
        { text: "Dibuat Tanggal", value: "timestamp" },
        { text: "Pengaturan", value: "action" },
      ],
      form: {
        imageFile: "",
        title: "",
        description: "",
        active: false,
        imageURL: "",
        fileName: "",
      },
      updateTemp: "",
      uploadValue: 0,
    };
  },

  filters: {
    moment: function (date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
  },

  methods: {
    upload(form) {
      const storageRef = uploadBytesResumable(
        sRef(getStorage(), "Promo/" + timeNow + form.imageFile.name),
        this.form.imageFile
      );
      this.dialog = false;
      this.dialogProgress = true;
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100 * 100
            ) / 100;
        },
        (err) => {
          console.log(err);
        },
        () => {
          getDownloadURL(storageRef.snapshot.ref).then((downloadURL) => {
            // console.log(downloadURL);
            this.form.imageURL = downloadURL;
            if (!this.editing) {
              this.saveData(form);
            } else {
              this.updateData(this.updateTemp, this.form);
            }
          });
        }
      );
    },

    alertChecker(action, item) {
      Swal.fire({
        title: "Yakin " + action + " data ?",
        text: "Jika sudah mengkonfirmasi data tidak bisa dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4CAF50",
        confirmButtonText: "Ya",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batalkan",
      }).then((result) => {
        if (result.isConfirmed) {
          if (action == "menghapus") {
            this.deleteStorage(item);
            // this.deleteData(itemID);
          }
        }
      });
    },

    realTImeReadData() {
      onSnapshot(db, (snapshot) => {
        let promotion = [];

        snapshot.docs.forEach((doc) => {
          promotion.push({ ...doc.data(), id: doc.id });
        });
        this.promotion = promotion;
      });
    },

    saveData(form) {
      addDoc(db, {
        active: form.active,
        image: form.imageURL,
        preview: form.description,
        title: form.title,
        fileName: timeNow + form.imageFile.name,
        timestamp: serverTimestamp(),
        updateData: serverTimestamp(),
        createBy: this.profileName,
        updateBy: this.profileName,
      })
        .then(() => {
          this.reset();
          setTimeout(() => {
            this.dialogProgress = false;
            this.uploadValue = 0;
          }, 1000);
        })
        .catch((e) => {
          alert("error save data ", e);
        });
    },

    async openEditDialog(item) {
      this.editing = true;
      this.form.imageURL = item.image;
      this.form.title = item.title;
      this.form.description = item.preview;
      this.form.active = item.active;
      this.dialog = true;
      this.updateTemp = item;

      console.log(this.updateTemp);
    },

    async saveUpdate() {
      if (this.form.imageFile !== "") {
        console.log("masuk 1");
        await this.deleteStorage(this.updateTemp);
      } else {
        console.log("masuk 2");

        await this.updateData(this.updateTemp, this.form);
      }
      console.log("saveupdate");

      console.log(this.form);
    },

    updateData(item, form) {
      const docRef = doc(getFirestore(), "promotion", item.id);

      if (form.fileName === "") {
        this.form.fileName = item.fileName;
      } else {
        this.form.fileName = timeNow + form.imageFile.name;
      }
      if (form.imageURL === "") {
        this.form.imageURL = item.image;
      }

      console.log("ini item ", item);
      console.log("ini form ", form);

      updateDoc(docRef, {
        active: form.active,
        image: form.imageURL,
        preview: form.description,
        title: form.title,
        fileName: form.fileName,
        updateData: serverTimestamp(),
        updateBy: this.profileName,
      })
        .then(() => {
          this.dialog = false;
          this.reset();
          setTimeout(() => {
            this.dialogProgress = false;
            this.uploadValue = 0;
          }, 1000);

          console.log("berhasil update semua");
        })
        .catch((e) => {
          console.log(e);
          this.dialog = false;
          setTimeout(() => {
            this.dialogProgress = false;
            this.uploadValue = 0;
          }, 1000);
        });
    },

    deleteStorage(item) {
      var reff = sRef(getStorage(), "News/" + item.fileName);
      console.log("delete storage ", this.form.imageFile);

      deleteObject(reff)
        .then(() => {
          if (!this.editing) {
            this.deleteData(item.id);
          } else {
            if (this.form.imageFile !== null) this.upload(this.form);
          }
          console.log("sudah hapus data jika edit");
        })
        .catch((e) => {
          console.log(e);
        });
    },

    deleteData(itemID) {
      const docRef = doc(getFirestore(), "promotion", itemID);
      deleteDoc(docRef).then(() => {
        // console.log("delete success", result);
        Swal.fire({
          icon: "success",
          title: "Sukses",
          text: "Data terpilih berhasil dihapus",
          confirmButtonColor: "#4CAF50",
          timer: 3000,
        });
      });
    },

    validate(form) {
      var isValid = this.$refs.form.validate();
      if (isValid) {
        if (this.editing) {
          this.saveUpdate();
        } else {
          this.upload(form);
        }
      }
    },

    reset() {
      this.$refs.form.reset();
      this.updateTemp = "";
      this.editing = false;
      this.form.description = "";
      this.form.active = "";
      this.form.imageFile = "";
      this.form.title = "";
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },

  mounted() {
    this.realTImeReadData();
    if (user !== null) {
      this.profileName = user.email.match(/^([^@]*)@/)[1];
    }
    console.log(getDocs(dataSorted));
    console.log("firestore");
    console.log("firestore", dataSorted);
  },
};
</script>

<style></style>
